import React from "react";
import "./styles/CookiesDetails.css";
import { Link } from "react-router-dom";

function CookiesDetails() {
  return (
    <div className="CookiesDetails">
      <div className="details-container">
        <h2 className="details-title">Zásady používání cookies</h2>
        <p className="details-content">
          Tato webová stránka používá cookies k vylepšení uživatelského zážitku.
          Zde je seznam cookies, které používáme:
        </p>
        <ul className="details-section">
          <li className="details-section-title">
            Session cookies:
            <p>
              Tyto cookies jsou nezbytně nutné pro správnou funkci našeho webu.
            </p>
          </li>
          <li className="details-section-title">
            Stálé cookies:
            <p>
              Tyto cookies zůstávají na zařízení uživatele po zavření prohlížeče
              a obvykle jsou používány k zapamatování si volby uživatele pro
              návštěvy v budoucnu.
            </p>
          </li>
          <li className="details-section-title">
            Analytické/performance cookies:
            <p>
              Tyto cookies nám umožňují rozpoznat a počítat počet návštěvníků a
              vidět, jak se návštěvníci pohybují po našem webu, když ho
              používají.
            </p>
          </li>
          {/* <li className="details-section-title">
          Funkční cookies:
          <p>
            Tyto cookies se používají k rozpoznání vás, když se vrátíte na náš
            web.
          </p>
        </li> */}
          {/* <li className="details-section-title">
          Reklamní cookies:
          <p>
            Tyto cookies zaznamenávají vaši návštěvu na našem webu, stránky,
            které jste navštívili, a odkazy, které jste sledovali.
          </p>
        </li> */}
        </ul>
        <p className="details-content">
          Pokud máte další otázky týkající se našeho používání cookies,
          neváhejte nás kontaktovat.
        </p>
        <p className="details-footer">
          Pro více informací o tom, jak nakládáme s vašimi osobními údaji, si
          můžete přečíst naše
          <Link to="/privacy-policy"> zásady ochrany osobních údajů</Link>.
        </p>
      </div>
    </div>
  );
}

export default CookiesDetails;
