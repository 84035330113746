import React from "react";
import Presentation from "./Presentations";
import image1 from "../../img/IMG_3049.jpg";
import image2 from "../../img/IMG_5376.jpg";
import image3 from "../../img/306683080_3235553393427492_1798989338213510916_n.jpg";
import image4 from "../../img/IMG_3526.jpg";
import image5 from "../../img/zelená hora_panorama.jpg";

function PresentationList() {
  return (
    <>
      <Presentation
        title="Jaro"
        subtitle="No. 1"
        text="Když příroda procitá a svět se znovu rozzáří."
        imageSrc={image1}
        styleType="1"
      ></Presentation>
      <Presentation
        title="Léto"
        subtitle="No. 2"
        text="Chvíle plné světla, života a nekonečných dobrodružství."
        imageSrc={image2}
        styleType="2"
      ></Presentation>
      <Presentation
        title="Podzim"
        subtitle="No. 3"
        text="Magické odstíny změny, kdy se listí stává plátnem."
        imageSrc={image3}
        styleType="3"
      ></Presentation>
      <Presentation
        title="Zima"
        subtitle="No. 4"
        text="Mrazivá krása, kdy je svět pokryt stříbrnou pokrývkou."
        imageSrc={image4}
        styleType="4"
      ></Presentation>
      <Presentation
        title="Panorama"
        subtitle="No. 5"
        text="Odkrývání světa v jeho plné šíři a nádheře."
        imageSrc={image5}
        styleType="5"
      ></Presentation>
    </>
  );
}

export default PresentationList;
