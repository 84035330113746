import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faComments } from "@fortawesome/free-regular-svg-icons";
import React from "react";
import { Link } from "react-router-dom";

function NavbarBottom() {
  return (
    <div className="NavbarBottom">
      <div className="navbarBottomWrap">
        <a
          href="https://www.instagram.com/michalsykoraphoto_/"
          target="_blank"
          rel="noopener noreferrer"
          className="NavbarBottomIcon"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=100007479016218"
          target="_blank"
          rel="noopener noreferrer"
          className="NavbarBottomIcon"
        >
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <Link to="/contactform" className="NavbarBottomIcon">
          <FontAwesomeIcon icon={faComments} />
        </Link>
      </div>
    </div>
  );
}

export default NavbarBottom;
