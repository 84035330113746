import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { Link } from "react-router-dom";

import "./styles/About.css";

import imgMichal from "../img/AboutMichal.jpg";
import imgFoto from "../img/337124313_172848388899439_9089468701028213753_n.jpg";

function About() {
  const michalTextRef = useRef(null);
  const titleRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      michalTextRef.current,
      {
        y: "100%",
        opacity: 0,
      },
      {
        duration: 2,
        y: "0%",
        opacity: 1,
        delay: 1,
        ease: "power1.out",
      }
    );
  }, []);

  useEffect(() => {
    gsap.fromTo(
      titleRef.current,
      {
        y: "100%",
        opacity: 0,
      },
      {
        duration: 2,
        y: "0%",
        opacity: 1,
        delay: 1,
        ease: "power1.out",
      }
    );
  }, []);

  return (
    <div className="About">
      <div className="About_image_container">
        <div className="About_image_container_left">
          <div className="About_image_container_left_text" ref={michalTextRef}>
            <h2>
              Michal <br /> Sýkora
            </h2>
          </div>

          <img src={imgMichal} alt="imgMichal" />
        </div>
        <div className="About_image_container_right">
          <div className="About_image_container_right_text" ref={titleRef}>
            <h2>Fotograf</h2>

            <p>
              Ahoj! Jsem nadšenec do fotografování, co už dva roky chytá
              neopakovatelné okamžiky do svého objektivu. Je to pro mě cesta,
              jak se neustále učit a růst.
            </p>

            <p>
              Dnes pracuji s vysoce kvalitním zařízením - Canon EOS 6D Mark II,
              doplněným objektivy Canon EF 20mm f/2,8 USM a Canon EF 75-300 mm
              f/4,0-5,6 DC III. Doufám, že si užijete prohlížení mých fotek
              stejně jako já jejich pořizování. Budu rád za každou vaši pěknou
              poznámku nebo dotaz. Mějte se krásně!
            </p>
          </div>
          <img src={imgFoto} alt="imgMichal" />
        </div>
      </div>
      <Link to="/contactform">
        <button className="About_contact_button"> Napiš mi</button>
      </Link>
    </div>
  );
}

export default About;
