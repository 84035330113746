import React from "react";
import { Link } from "react-router-dom";
import "./styles/PrivacyPolicy.css";

function PrivacyPolicy() {
  return (
    <div className="PrivacyPolicy">
      <div className="privacy-container">
        <h1>Zásady ochrany osobních údajů</h1>

        <section className="introduction">
          <p>
            Vážíme si důvěry, kterou nám vkládáte, a bereme vážně ochranu vašich
            osobních údajů. V této politice vysvětlujeme, jaké informace
            sbíráme, jak je používáme a jaká práva a možnosti máte v souvislosti
            s vašimi údaji.
          </p>
        </section>

        <section className="data-collection">
          <h2>Co sbíráme?</h2>
          <p>
            Naše webové stránky mohou sbírat následující informace:
            <ul>
              <li>
                Identifikační údaje: jméno, příjmení, e-mail, telefonní číslo.
              </li>
              <li>Technické údaje: IP adresa, prohlížeč, poloha, cookies.</li>
              <li>Uživatelské preference: nastavení jazyka, oblíbený obsah.</li>
            </ul>
          </p>
        </section>

        <section className="data-use">
          <h2>Jak používáme vaše údaje?</h2>
          <p>
            Vámi poskytnuté údaje nám pomáhají vylepšovat naše služby,
            personalizovat obsah a nabízet vám produkty a služby, které by vás
            mohly zajímat.
          </p>
        </section>

        <section className="rights">
          <h2>Vaše práva</h2>
          <p>
            Máte právo na přístup k vašim osobním údajům, právo na opravu nebo
            výmaz vašich osobních údajů a právo vznést námitku proti zpracování
            vašich údajů. K vykonání těchto práv nás můžete kdykoli kontaktovat.
          </p>
        </section>

        <section className="contact">
          <h2>Kontaktujte nás</h2>
          <p>
            Pokud máte jakékoli otázky týkající se zásad ochrany osobních údajů
            nebo chcete uplatnit svá práva, můžete nás kontaktovat
            prostřednictvím <Link to="/contactform">kontaktního formuláře</Link>.
          </p>
        </section>

        <footer>
          <p>
            Tato politika byla naposledy aktualizována{" "}
            {new Date().getFullYear()}.
          </p>
        </footer>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
