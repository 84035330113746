import React, { useEffect, useRef } from "react";
import "./Presentations.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);

function Presentation({ title, subtitle, text, imageSrc, styleType }) {
  const textRef = useRef(null);
  const imgRef = useRef(null);
  const lineRef = useRef(null);
  const slideLinkRef = useRef(null);

  const galleryMapping = {
    1: "jaro",
    2: "leto",
    3: "podzim",
    4: "zima",
    5: "panorama",
  };

  // Link hover effects
  const mouseOverHandler = (e) => {
    gsap.to(lineRef.current, {
      x: 5,
      scaleX: 0.6,
      ease: "power1",
    });
  };

  const mouseOutHandler = (e) => {
    gsap.to(lineRef.current, {
      x: 0,
      scaleX: 1,
    });
  };

  // Animation to show the text when it comes into view
  useEffect(() => {
    gsap.fromTo(
      textRef.current,
      { y: 150, autoAlpha: 0 },
      {
        y: 0,
        autoAlpha: 1,
        duration: 2.5,
        ease: "expo.out",
        scrollTrigger: {
          trigger: textRef.current,
          start: "top 100%",
        },
      }
    );
  }, []);

  useEffect(() => {
    // Uložení aktuálních inline stylů pro možné pozdější obnovení.
    ScrollTrigger.saveStyles(".col__content-title");

    ScrollTrigger.matchMedia({
      // desktop
      "(min-width: 800px)": function () {
        gsap.utils.toArray(".col__content-title").forEach((element) => {
          gsap.to(element, {
            scale: 1.3,
            xPercent: -50,
            yPercent: 15,
            top: "0%",
            left: "50%",
            scrollTrigger: {
              trigger: element,
              start: "bottom bottom",
              end: "bottom center",
              scrub: 1.5,
            },
          });
        });
      },

      // mobile
      "(max-width: 799px)": function () {
        // mobile
        gsap.utils.toArray(".col__content-title").forEach((element) => {
          gsap.to(element, {
            scale: 1.2,
            xPercent: -50,
            yPercent: -10,
            top: "0%",
            left: "50%",
            scrollTrigger: {
              trigger: element,
              start: "bottom bottom",
              end: "bottom center",
              scrub: 1,
            },
          });
        });
      },
    });
  }, []);

  // Image animation
  useEffect(() => {
    // Uložení aktuálních inline stylů pro možné pozdější obnovení.
    ScrollTrigger.saveStyles(".mobile, .desktop");

    ScrollTrigger.matchMedia({
      // desktop
      "(min-width: 800px)": function () {
        // Animace a ScrollTriggers pro desktopová zařízení.
        gsap.to(imgRef.current, {
          yPercent: 50,
          ease: "linear",
          scrollTrigger: {
            trigger: imgRef.current,
            start: "top center",
            end: "bottom top",
            scrub: 2,
          },
        });
      },

      // mobile
      "(max-width: 799px)": function () {
        // Aplikace hardwareové akcelerace pro mobilní zařízení.
        if (imgRef && imgRef.current) {
          imgRef.current.style.transform = "translate3d(0, 0, 0)";
        }

        // Animace a ScrollTriggers pro mobilní zařízení.
        gsap.to(imgRef.current, {
          yPercent: 20,
          ease: "power1.out",
          scrollTrigger: {
            trigger: imgRef.current,
            start: "top center",
            end: "bottom top",
            scrub: 0,
          },
        });
      },
    });
  }, []);

  // Link hover effects
  useEffect(() => {
    const currentSlideLinkRef = slideLinkRef.current;
    if (currentSlideLinkRef) {
      currentSlideLinkRef.addEventListener("mouseover", mouseOverHandler);
      currentSlideLinkRef.addEventListener("mouseout", mouseOutHandler);
    }

    return () => {
      if (currentSlideLinkRef) {
        currentSlideLinkRef.removeEventListener("mouseover", mouseOverHandler);
        currentSlideLinkRef.removeEventListener("mouseout", mouseOutHandler);
      }
    };
  }, []);

  return (
    <div className={`style-${styleType}`}>
      <section className={"intro slide"}>
        <div className="col col--1">
          <div className="col__content">
            <div className="col__content-title" data-style-type={styleType}>
              {title}
              <span className="line__inner_no"> {subtitle}</span>
            </div>
            <div className="col__content-wrap">
              <p className="col__content-txt" ref={textRef}>
                {text}
              </p>

              <Link
                to={`/gallery/${galleryMapping[styleType]}`}
                className="slide-link"
                ref={slideLinkRef}
              >
                <div className="slide-link__circ">
                  <div className="slide-link__line" ref={lineRef}></div>
                </div>
                <div className="slide-link__text">
                  <p>více z alba</p>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="col col--2">
          <div className="col__image-wrap">
            <img
              ref={imgRef}
              className="img img--1"
              src={imageSrc}
              alt="Obrázek alba"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Presentation;
