import React, { useState, useRef } from "react";
import { gsap } from "gsap";
import "./Navbar.css";

import { Link } from "react-router-dom";

function NavbarTop() {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const topBarRef = useRef(null);
  const middleBarRef = useRef(null);
  const bottomBarRef = useRef(null);
  const sideMenuRef = useRef(null);
  const navItemsRef = useRef([]);

  navItemsRef.current = ["Já", "FOTO", "Kontakt", "Sociální sítě"].map(
    (_, i) => navItemsRef.current[i] ?? React.createRef()
  );

  const handleOnClick = () => {
    setIsOpen(!isOpen);

    const tl = gsap.timeline();

    if (!isOpen) {
      // animace pro otevření menu
      tl.to(topBarRef.current, {
        rotation: 45,
        y: 10,
        scaleX: "1.4",
        duration: 0.3,
      })
        .to(
          bottomBarRef.current,
          {
            rotation: -45,
            y: -10,
            duration: 0.3,
          },
          "<"
        )
        .to(middleBarRef.current, { autoAlpha: 0 }, "<") // schová střední čáru
        .to(
          sideMenuRef.current,
          {
            x: "0",
            duration: 0.5,
          },
          "<"
        );

      navItemsRef.current.forEach((ref, i) => {
        tl.fromTo(
          ref.current,
          { autoAlpha: 0, y: -10 },
          { autoAlpha: 1, y: 0, delay: i * 0.1, duration: 0.3 },
          "<"
        );
      });
    } else {
      // animace pro zavření menu
      tl.to(topBarRef.current, {
        rotation: 0,
        y: 0,
        scaleX: "1",
        duration: 0.1,
      })
        .to(
          bottomBarRef.current,
          {
            rotation: 0,
            y: 0,
            duration: 0.1,
          },
          "<"
        )
        .to(middleBarRef.current, { autoAlpha: 1 }, "<") // zobrazí střední čáru
        .to(
          sideMenuRef.current,
          {
            x: "-100%",
            duration: 0.5,
          },
          "<"
        );
    }
  };

  const navLinks = [
    { label: "| Domů", path: "/" },
    { label: "| Michal Sýkora", path: "/about" },
    { label: "| Galerie", path: "/gallery/jaro" },
    { label: "| Kontakt", path: "/contactform" },
  ];

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/">
          <h2>Sykoraphoto</h2>
        </Link>
        <div className="navbar-menu" ref={menuRef} onClick={handleOnClick}>
          <div className="line top" ref={topBarRef} />
          <div className="line middle" ref={middleBarRef} />
          <div className="line bottom" ref={bottomBarRef} />
        </div>
      </div>
      <div className="side-menu" ref={sideMenuRef}>
        {navLinks.map((link, index) => (
          <Link
            key={link.label}
            to={link.path}
            ref={navItemsRef.current[index]}
            className="nav-item"
          >
            {link.label}
          </Link>
        ))}
      </div>
    </nav>
  );
}

export default NavbarTop;
