import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="site-footer">
      <div className="footer-links">
        <Link to="/">Domů</Link>
        <Link to="/about">Michal Sýkora</Link>
        <Link to="/contactform">Kontakt</Link>
        <Link to="/privacy-policy">Zásady ochrany osobních údajů</Link>
        <Link to="/cookies">Cookies</Link>
      </div>
      <div className="footer-copyright">
        © {currentYear} michalsykoraphoto. Všechna práva vyhrazena.
      </div>
    </footer>
  );
}

export default Footer;
