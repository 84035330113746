import React, { useState } from "react";
import "./styles/CookieBanner.css";

import { Link } from "react-router-dom";

function CookiesBanner() {
  const lastAcceptedDate = new Date(
    localStorage.getItem("cookiesAcceptedDate")
  );
  const currentDate = new Date();
  const differenceInDays =
    (currentDate - lastAcceptedDate) / (1000 * 60 * 60 * 24);
  const [isVisible, setIsVisible] = useState(
    differenceInDays > 30 || !localStorage.getItem("cookiesAccepted")
  );

  const [selectedCookies, setSelectedCookies] = useState({
    essential: true,
    analytics: false,
    // advertising: false,
    functional: false,
  });

  const handleCheckboxChange = (event) => {
    setSelectedCookies({
      ...selectedCookies,
      [event.target.name]: event.target.checked,
    });
  };

  const acceptCookies = () => {
    const currentDate = new Date();
    localStorage.setItem("cookiesAcceptedDate", currentDate.toString());
    localStorage.setItem("cookiesAccepted", JSON.stringify(selectedCookies));
    setIsVisible(false);
    // Nastavte cookies na základě výběru uživatele
  };

  const rejectCookies = () => {
    localStorage.setItem("cookiesAccepted", "false");
    setIsVisible(false);
    // Odstraňte cookies
  };

  const closeBanner = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }
  console.log(isVisible);
  return (
    <div className={`cookie-banner ${isVisible ? "active" : ""}`}>
      <span className="close-icon" onClick={closeBanner}>
        ×
      </span>
      <p>
        Tato webová stránka používá cookies k vylepšení uživatelského zážitku.
        Pokračováním v prohlížení této stránky souhlasíte s naším používáním
        cookies. <Link to="/cookies">Více informací</Link>
      </p>
      <div className="cookie-options">
        <label>
          <input
            type="checkbox"
            name="essential"
            checked={selectedCookies.essential}
            onChange={handleCheckboxChange}
            disabled
          />
          Nezbytné cookies
        </label>
        <label>
          <input
            type="checkbox"
            name="analytics"
            checked={selectedCookies.analytics}
            onChange={handleCheckboxChange}
          />
          Analytické cookies
        </label>
        {/* <label>
          <input
            type="checkbox"
            name="advertising"
            checked={selectedCookies.advertising}
            onChange={handleCheckboxChange}
          />
          Reklamní cookies
        </label> */}
        <label>
          <input
            type="checkbox"
            name="functional"
            checked={selectedCookies.functional}
            onChange={handleCheckboxChange}
          />
          Funkční cookies
        </label>
      </div>
      <div className="cookiesButton">
        <button className="accept-button" onClick={acceptCookies}>
          Uložit preference
        </button>
        <button onClick={rejectCookies}>Odmítnout vše</button>
      </div>
      <p>
        Podívejte se na naše{" "}
        <Link to="/privacy-policy"> zásady ochrany osobních údajů</Link>.
      </p>
    </div>
  );
}

export default CookiesBanner;
