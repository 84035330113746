import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import "./styles/componentsStyles.css";

function PasswordProtection() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const auth = getAuth(); // Získání instance Auth

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/upload");
    } catch (err) {
      console.error(err);
      setError(true);
    }
  };

  return (
    <div className="password-section">
      <h2>Login</h2>
      {error && <p style={{ color: "red" }}>Špatný email nebo heslo.</p>}
      <form onSubmit={handleSignIn}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter email..."
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter password..."
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default PasswordProtection;
