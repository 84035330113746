import React from "react";

import { Route, Routes } from "react-router-dom";

import Navbar from "./component/navbar/Navbar";

import Home from "./component/Home";

import Gallery from "./component/Gallery";

import NotFound from "./component/NotFound";

import UploadPhoto from "./component/UploadPhoto";
import PasswordProtection from "./component/PasswordProtection";

import Contactform from "./component/ContactForm";

import CookiesBanner from "./component/CookiesBannner";
import CookiesDetails from "./component/CookiesDetails";
import PrivacePolicy from "./component/PrivacePolicy";

import About from "./component/About";

import "./App.css";

function App() {
  return (
    <div className="App">
      <Navbar />
      <CookiesBanner />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/upload" element={<UploadPhoto />} />
        <Route path="/password" element={<PasswordProtection />} />
        <Route path="/gallery/:galleryType" element={<Gallery />} />
        <Route path="/contactform" element={<Contactform />} />
        <Route path="/about" element={<About />} />
        <Route path="/cookies" element={<CookiesDetails />} />
        <Route path="/privacy-policy" element={<PrivacePolicy />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
