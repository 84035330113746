import React, { useLayoutEffect, useRef } from "react";
import LandingPage from "./landingpage/LandingPage";
import PresentationList from "./presentation/PresentationList";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import Footer from "./footer/Footer";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

function Home() {
  const main = useRef();

  useLayoutEffect(() => {
    // Inicializujte SmoothScroll pouze pokud to není mobilní zařízení

    main.current = ScrollSmoother.create({
      smooth: 2,
      smoothTouch: 0.1,
      effects: true,
    });
  }); // Přidáme isMobile do závislostí, abychom reagovali na změnu

  return (
    <div ref={main}>
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <section>
            <LandingPage />
          </section>
          <section>
            <PresentationList />
          </section>
          <section>
            <Footer />
          </section>
        </div>
      </div>
    </div>
  );
}

export default Home;
