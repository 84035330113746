import React, { useState, useEffect } from "react";
import "./styles/componentsStyles.css";

import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import {
  doc,
  setDoc,
  deleteDoc,
  getFirestore,
  collection,
  getDocs,
} from "firebase/firestore";
import { storage } from "./services/firebase";

function UploadPhoto() {
  const db = getFirestore();

  const [selectedGallery, setSelectedGallery] = useState("jaro");
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState("");
  const [photosList, setPhotosList] = useState([]);

  useEffect(() => {
    const fetchPhotos = async () => {
      const photoCollection = await getDocs(collection(db, "photos"));
      setPhotosList(
        photoCollection.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    };
    fetchPhotos();
  }, [db]);

  const handleUpload = () => {
    if (!file) {
      alert("Please select a photo to upload.");
      return;
    }

    // Omezení velikosti souboru na 5MB
    const maxSizeInMB = 8;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      alert(
        `Soubor je příliš velký. Maximální povolená velikost je ${maxSizeInMB}MB.`
      );
      return;
    }

    const storageRef = ref(storage, `${selectedGallery}/images/${file.name}`);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        console.log(error);
        alert("An error occurred while uploading the file.");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          console.log("Soubor přístupný na", downloadURL);
          alert("Soubor úspěšně nahrán!");

          // Save the URL and description to Firestore
          const docRef = doc(db, "photos", file.name); // Using the file name as the document ID
          await setDoc(docRef, {
            url: downloadURL,
            description: description,
            gallery: selectedGallery,
          });
        });
      }
    );
  };

  const handleDelete = async (photoId, gallery) => {
    // Delete the file from Firebase Storage
    const storageRef = ref(storage, `${gallery}/images/${photoId}`);
    await deleteObject(storageRef);

    // Delete the file record from Firestore
    const docRef = doc(db, "photos", photoId);
    await deleteDoc(docRef);

    alert("File deleted successfully!");

    // Update the photos list
    setPhotosList((prevList) =>
      prevList.filter((photo) => photo.id !== photoId)
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleUpload();
  };
  return (
    <div className="upload_wrap">
      <div className="upload-section">
        <h2>Nahrát fotografii</h2>
        <form onSubmit={handleSubmit}>
          <input type="file" onChange={(e) => setFile(e.target.files[0])} />
          <textarea
            placeholder="Vlož popisek..."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <select
            value={selectedGallery}
            onChange={(e) => setSelectedGallery(e.target.value)}
          >
            <option value="jaro">Jaro</option>
            <option value="leto">Léto</option>
            <option value="podzim">Podzim</option>
            <option value="zima">Zima</option>
            <option value="panorama">Panorama</option>
          </select>
          <br />
          <button type="submit">Nahrát</button>
        </form>
      </div>

      <ul className="photos-list">
        <h3>Seznam fotografií</h3>
        {photosList.map((photo) => (
          <li key={photo.id}>
            {photo.description} - {photo.gallery}
            <button onClick={() => handleDelete(photo.id, photo.gallery)}>
              Odstranit
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default UploadPhoto;
