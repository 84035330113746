import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import image1 from "../../img/306683080_3235553393427492_17989893382135109162_n .jpg";
import image2 from "../../img/363503073_294377586432744_293567640881720488_n.jpg";

import "./LandingPage.css";

import Birds from "./BirdsFly";

function LandingPage() {
  const imageRef1 = useRef(null);
  const imageRef2 = useRef(null);
  const heroRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    //Img
    gsap.fromTo(
      imageRef1.current,
      { xPercent: 15, yPercent: -50, autoAlpha: 0 },
      {
        yPercent: 40,
        autoAlpha: 0.8,
        duration: 3.5,
        ease: "slow(1, 2, false)",
        delay: 3,
      }
    );
    gsap.fromTo(
      imageRef2.current,
      { xPercent: 25, yPercent: 50, autoAlpha: 0 },
      {
        yPercent: -30,
        autoAlpha: 0.9,
        duration: 3.5,
        ease: "slow(1, 2, false)",
        delay: 3,
      }
    );
    //Hero
    gsap.fromTo(
      heroRef.current,
      { y: 240 },
      { y: 0, duration: 3, ease: "power2.in" }
    );
    gsap.to(heroRef.current, {
      duration: 3,
      clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
      ease: "power2.inOut",
    });

    //Text
    gsap.fromTo(
      textRef.current,
      { x: -800 },
      { x: 0, duration: 1, ease: "power1", delay: 4 }
    );
    gsap.fromTo(
      textRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 5, delay: 3 }
    );
  }, []);

  return (
    <div className="landing">
      <Birds></Birds>

      <div className="landing_wrap">
        <div className="landing_wrap_col1">
          <img src={image1} ref={imageRef1} alt="Obrazek 1" />
          <img src={image2} ref={imageRef2} alt="Obrazek 2" />
        </div>

        <div className="landing_wrap_col2">
          <div className="landing__hero" ref={heroRef}>
            <h1>SYKORA</h1>
          </div>
          <div className="landing__text" ref={textRef}>
            Vítejte v mém fotografickém světě! Ponořte se do mého portfolia a
            objevte moje nejlepší práce, které odrazí mou vášeň pro fotografii,
            smysl pro detail a jedinečný styl. Od osobních projektů až po
            komerční zakázky, každý snímek vypráví příběh.
          </div>
        </div>
      </div>
      <div className="center">
        <svg width="30" height="70" viewBox="0 0 50 130">
          <rect
            id="scroll"
            x="0"
            rx="25"
            ry="25"
            width="50"
            height="120"
            stroke="#FFF"
            fill="#c0d7d8"
          ></rect>
          <circle id="circle--shape" cx="25" cy="32" r="8" fill="#fff"></circle>
        </svg>
      </div>
    </div>
  );
}

export default LandingPage;
