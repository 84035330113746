import React from "react";
import "./BirdsFly.css";

function BirdsFly() {
  return (
    <div className="Birds">
      <div className="bird-container bird-container-one">
        <div className="bird bird-one"></div>
      </div>
      <div className="bird-container bird-container-two">
        <div className="bird bird-two"></div>
      </div>
      <div className="bird-container bird-container-three">
        <div className="bird bird-three"></div>
      </div>
      <div className="bird-container bird-container-four">
        <div className="bird bird-four"></div>
      </div>
      <div className="bird-container bird-container-five">
        <div className="bird bird-five"></div>
      </div>
      <div className="bird-container bird-container-six">
        <div className="bird bird-six"></div>
      </div>
    </div>
  );
}

export default BirdsFly;
