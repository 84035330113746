import React, { useState, useEffect } from "react";
import "./styles/gallery.css";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
  limit as firestoreLimit,
} from "firebase/firestore";
import { useParams, useNavigate } from "react-router-dom";

import gallery_background from "../img/Sykora.png";

function Gallery() {
  const [limit, setLimit] = useState(8); // Počet načtených obrázků
  const [hasMore, setHasMore] = useState(true); // Zda existují další obrázky k načtení
  const navigate = useNavigate();
  const { galleryType } = useParams();
  const [selectedGallery, setSelectedGallery] = useState(galleryType || "jaro");
  const [photos, setPhotos] = useState([]);
  const db = getFirestore();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalOpen(false);
  };

  const navigateToNextImage = (e) => {
    e.stopPropagation(); // Přidejte tuto řádku
    const currentImageUrls = photos.map((photo) => photo.url);
    const currentIndex = currentImageUrls.indexOf(selectedImage);
    if (currentIndex < currentImageUrls.length - 1) {
      setSelectedImage(currentImageUrls[currentIndex + 1]);
    }
  };

  const navigateToPreviousImage = (e) => {
    e.stopPropagation(); // Přidejte tuto řádku
    const currentImageUrls = photos.map((photo) => photo.url);
    const currentIndex = currentImageUrls.indexOf(selectedImage);
    if (currentIndex > 0) {
      setSelectedImage(currentImageUrls[currentIndex - 1]);
    }
  };

  useEffect(() => {
    const loadPhotos = async () => {
      setIsLoading(true);

      const photosCollection = collection(db, "photos");
      const q = query(
        photosCollection,
        where("gallery", "==", selectedGallery),
        firestoreLimit(limit) // Limit načtených obrázků
      );

      const photoSnapshots = await getDocs(q);
      const photosData = photoSnapshots.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Pokud počet načtených obrázků je menší než limit, pak neexistují další obrázky k načtení
      setHasMore(photosData.length === limit);

      setPhotos(photosData);
      setIsLoading(false);
    };

    loadPhotos();
  }, [selectedGallery, db, limit]);

  const loadMorePhotos = () => {
    setLimit((prevLimit) => prevLimit + 8); //přidá další img
  };

  useEffect(() => {
    if (galleryType !== selectedGallery) {
      navigate(`/gallery/${selectedGallery}`);
    }
  }, [selectedGallery, galleryType, navigate]);

  return (
    <div className="gallery">
      <div className="sykora_bg">
        <img src={gallery_background} alt="" />
      </div>
      <div className="gallery-selector">
        <select
          value={selectedGallery}
          onChange={(e) => setSelectedGallery(e.target.value)}
        >
          <option value="jaro">Jaro</option>
          <option value="leto">Léto</option>
          <option value="podzim">Podzim</option>
          <option value="zima">Zima</option>
          <option value="panorama">Panorama</option>
        </select>
      </div>
      {isLoading
        ? Array(15)
            .fill(0)
            .map((_, idx) => (
              <div key={idx} className="skeleton">
                <div className="skeleton_foto"></div>
                <div className="skeleton_text"></div>
              </div>
            ))
        : photos.map((photo) => (
            <div key={photo.id} className="photo-item">
              <img
                src={photo.url}
                alt={photo.description}
                style={{ width: "300px", margin: "10px" }}
                onClick={() => openModal(photo.url)}
              />
              <p>{photo.description}</p>
            </div>
          ))}

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} alt="Zvětšený obrázek" />
            <button className="close-modal-btn" onClick={closeModal}>
              ×
            </button>
          </div>
          <button
            className="modal-content-btn"
            onClick={(e) => navigateToPreviousImage(e)}
          >
            ←
          </button>
          <button
            className="modal-content-btn"
            onClick={(e) => navigateToNextImage(e)}
          >
            →
          </button>
        </div>
      )}

      {hasMore && (
        <button onClick={loadMorePhotos} className="gallery-nxtBtn">
          Načíst další obrázky
        </button>
      )}
    </div>
  );
}

export default Gallery;
