import React from "react";
import NavbarBottom from "./NavbarBottom";
import NavbarTop from "./NavbarTop";

function Navbar() {
  return (
    <div>
      <NavbarTop></NavbarTop>
      <NavbarBottom></NavbarBottom>
    </div>
  );
}

export default Navbar;
