import "./styles/ContactForm.css";
import Birds from "./landingpage/BirdsFly";

function ContactForm() {
  return (
    <div className="ContactForm">
      <Birds></Birds>

      <form
        method="POST"
        name="contact"
        netlify
        netlify-honeypot="bot-field"
        action="/success"
      >
        <input type="hidden" name="form-name" value="contact" />
        <input required type="text" name="name" placeholder="Jméno" />
        <input required type="email" name="email" placeholder="Email" />
        <textarea name="message" placeholder="Zpráva" />
        <button type="submit">Odeslat</button>
      </form>
    </div>
  );
}

export default ContactForm;
